<template>
	<Metier name="inspection" title="Inspection" :main-menu="[{title: 'Contremaître Inspection', missions: ['Gère l\'activité des équipes d\'inspecteurs (planning, budget, contrats…)', 'Participe à l\'amélioration des méthodes du service via les retours d\'expérience', 'S\'assure du respect des réglementations via sa veille des évolutions réglementaires et sa participation aux réunions avec la DREAL'], competences: ['Expériences de gestion d\'équipes opérationnelles', 'Expérience dans des postes d\'inspection, de maintenance ou d\'exploitation', 'Connaissance du raffinage et du milieu pétrolier', 'Connaissance des réglementations de l\'inspection en raffinerie'], qualites: ['Rigueur', 'Leadership', 'Organisation']}, {title: 'Inspecteur', missions: ['Met en œuvre le plan d\'inspection de son secteur et rédige les rapports d\'inspection', 'Emet des préconisations suite aux inspections', 'Participe à la préparation et la réalisation des travaux de son secteur', 'Veille à l\'application de la réglementation sur son secteur'], competences: ['Expérience dans le milieu du raffinage', 'Certification CTNIIC niveau 1 minimum', 'Connaissance des réglementations de l\'inspection en raffinerie'], qualites: ['Autonomie', 'Rigueur', 'Fermeté']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
